<template>
  <div ref="receiveGift" class="receiveGift">
    <p class="name">{{ $t('领取试驾礼品') }}</p>
    <div class="domain">
      <div class="domain-i" @click="showRule"><img src="./images/rule.png" class="domain_icon"/>{{ $t('活动规则') }}</div>
      <div class="domain-i" @click="showHistory"><img src="./images/gift.png" class="domain_icon"/>{{ $t('获奖记录') }}</div>
    </div>
    <div ref="giftBox" class="giftBox" @mouseenter="mouseenter" @mouseleave="mouseleave">
      <div ref="scrollBox" class="scrollBox">
        <div v-for="(item, index) in giftList" :key="index" ref="gift-i" class="gift-i">
          <span class="productName">{{ item.productName }}</span>
          <img :src="item.productImage"/>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="outer">
        <div class="btn" @click="lottery">{{ $t('开启试驾盲盒') }}</div>
      </div>
    </div>
    <!-- 规则弹窗 -->
    <van-dialog
      v-model="ruleDialog"
      title=""
      :show-cancel-button="false">
      <div class="dialogContet">
        <p class="title">{{ $t('活动规则') }}</p>
        <div v-html="activityRules"></div>
      </div>
    </van-dialog>
    <!-- 获奖记录弹窗 -->
    <van-dialog
      v-model="historyDialog"
      title="">
      <div class="dialogContet">
        <p class="title">{{ $t('获奖记录') }}</p>
        <p>{{ $t('获奖时间') }}：{{ giftHistory.createTime || $t('暂无') }}</p>
        <p>{{ $t('获奖礼品') }}：{{ giftHistory.productName || $t('暂无') }}</p>
        <p class="title">{{ $t('收货人信息') }}</p>
        <p>{{ $t('姓名') }}：{{ giftHistory.consigneeName || $t('暂无') }}</p>
        <p>{{ $t('手机号码') }}：{{ giftHistory.consigneePhone || $t('暂无') }}</p>
        <p>{{ $t('收货地址') }}：{{ addressInfo }}</p>
      </div>
    </van-dialog>
    <!-- 领取礼品弹窗 -->
    <van-dialog
      v-model="lotteryDialog"
      title=''
      closeOnClickOverlay
      :showConfirmButton="false">
      <div class="lotteryBox">
        <!-- <p class="title">{{ $t('中奖了') }}</p> -->
        <img class="winIcon" src="./images/win_icon.png"/>
        <img class="giftPng" :src="giftInfo.productImage">
        <p>{{ `${$t('恭喜您获得')}【${giftInfo.productName}】${$t('礼品一份')}` }}</p>
        <p>{{ $t('请填写您的收件地址！') }}</p>
        <van-form ref="form" class="formBox" @submit.native.prevent>
          <van-field
            v-model="submitParams.consigneeName"
            :label="$t('姓名')"
            required
            :rules="[{required: true}]"
            :placeholder="$t('请输入收货人姓名')">
          </van-field>
          <van-field
            v-model="submitParams.consigneePhone"
            :label="$t('手机号码')"
            required
            :rules="[{required: true}]"
            :placeholder="$t('请输入收货人手机号')">
          </van-field>
          <van-field
            v-model="submitParams.verifyCode"
            clearable
            label="验证码"
            required
            type="digit"
            maxlength=6
            :rules="[{required: true},{ pattern: /^\d{6}$/, trigger: 'onBlur', message: '请输入6位验证码' }]"
            placeholder="请输入验证码"
            @click-right-icon="verifyCode = ''">
            <template #right-icon>
              <van-button size="small" type="primary" :disabled="hasSend" @click="sendSMS">{{hasSend ? `${smsTime}秒后重试` : '发送验证码'}}</van-button>
            </template>
          </van-field>
          <van-field
            v-model="cityInfo"
            :label="$t('省市区')"
            required
            readonly
            :rules="[{required: true}]"
            :placeholder="$t('请选择省市区')"
            @click="showArea=true">
          </van-field>
          <van-field
            v-model="submitParams.consigneeAddress"
            :label="$t('详细地址')"
            required
            :rules="[{required: true}]"
            :placeholder="$t('请输入收货人详细地址')">
          </van-field>
          <van-button class="submitBtn" type="submit" @click="onSubmit">{{ $t('领取试驾礼') }}</van-button>
        </van-form>
      </div>
    </van-dialog>
    <van-popup v-model="showArea" position="bottom">
      <van-area
        :area-list="areaList"
        :columns-num="3"
        @confirm="onCityConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
  </div>
</template>
<script>
import testDriveServices from '@/services/testDrive'
import loading from '@/utils/loading'
import { areaList } from '@vant/area-data'
export default {
  data() {
    return {
      ruleDialog: false,
      historyDialog: false,
      lotteryDialog: false,
      activityRules: '',
      appointId: '',
      appointmentid: '',
      giftList: [],
      giftListOld: [],
      giftInfo: {}, // 中奖信息
      giftHistory: {}, // 获奖记录
      submitParams: {
        consigneeName: '',
        consigneePhone: '',
        verifyCode: '',
        productId: '',
        productName: '',
        consigneeAddress: '',
        province: '',
        city: '',
        region: '',
      },
      showArea:  false, // 省市区选择
      areaList,
      timer: null,
      scrollWidth: 0,
      accessToken: '', // 小程序/app的token
      tokenCount: 0,
      smsTime: 0,
      hasSend: false,
    }
  },
  computed: {
    cityInfo() {
      return `${this.submitParams.province}${this.submitParams.city}${this.submitParams.region}`
    },
    addressInfo() {
      let str = ''
      const { province='', city='', region='', consigneeAddress='' } = this.giftHistory
      str = `${province}${city}${region}${consigneeAddress}`
      return str || this.$t('暂无')
    }
  },
  watch: {
    giftList: {
      handler(val) {
        if (val.length > 0) {
          const length = this.giftList.length
          if (length == 1) {
            for (var i=0; i<=6; i++){
              this.giftList.push(...this.giftListOld)
            }
          }
          if (length == 2) {
            for (var i=0; i<=3; i++){
              this.giftList.push(...this.giftListOld)
            }
          }
          if (length == 3) {
            for (var i=0; i<=2; i++){
              this.giftList.push(...this.giftListOld)
            }
          }
          if ([4,5,6].includes(length)) {
            for (var i=0; i<=1; i++){
              this.giftList.push(...this.giftListOld)
            }
          }
        }
      },
      immediate: true,
      deep: true
    },
  },
  mounted() {
    const { lang, appointmentid, from, accessToken } = this.$route.query
    this.appointId = appointmentid
    this.submitParams.appointId = this.appointmentid
    this.accessToken = accessToken // 从小程序获取到的token
    if (lang) this.$setLang(lang.includes('en') ? 'en' : 'zh')
    this.getGiftList()
    if (from === 'app' && !accessToken) {
      this.refreshToken()
    }
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    mouseenter() {
    },
    mouseleave() {
    },
    // 滚动展示礼品
    scrollFun() {
      const giftBox = this.$refs.scrollBox
      const maxWidth = this.$refs['gift-i'][0].offsetWidth
      let move = () => {
        this.scrollWidth ++
        if (this.scrollWidth >= maxWidth) {
          this.scrollWidth = 0
          let temData = [...this.giftList]
          let tempDataArr = temData.splice(0,1)
          temData.push(...tempDataArr)
          this.giftList = temData
        }
        giftBox.style.left = '-' + this.scrollWidth + 'px'
      }
      clearInterval(this.timer)
      this.timer = setInterval(move, 40)
    },
    // 展示规则
    async showRule() {
      loading.showLoading()
      const res = await testDriveServices.giftConfigDetailByThirid({})
      loading.hideLoading()
      this.activityRules = res.activityRules ? res.activityRules.replaceAll('\n', '</br>') : ''
      this.ruleDialog = true
    },
    // 展示历史
    async showHistory() {
      loading.showLoading()
      const res = await testDriveServices.getGiftDetailByThirid({ appointId: this.appointId })
      loading.hideLoading()
      this.giftHistory = res
      this.historyDialog = true
    },
    // 获取礼品列表
    async getGiftList() {
      loading.showLoading()
      const res = await testDriveServices.getGiftListByThirid()
      this.giftList = Array.isArray(res) ? res : [] 
      this.giftListOld = JSON.parse(JSON.stringify(this.giftList))
      this.$nextTick(() => {
        this.scrollFun()
      })
      loading.hideLoading()
    },
    // 获取验证码
    sendSMS() {
      if (!/^1[3456789]\d{9}$/.test(this.submitParams.consigneePhone)) {
        this.$toast('请输入正确的手机号!')
        return
      }
      testDriveServices.sendSmsVerify({ phone: this.submitParams.consigneePhone }).then((res) => {
        if (res.code === 0) {
          this.hasSend = true
          this.$toast('验证码已发送')
          this.smsTime = 60
          const timer = setInterval(() => {
            if (this.smsTime-- <= 0) {
              clearInterval(timer)
              // 重新启用按钮
              this.hasSend = false
            }
          }, 1000)
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    // 抽奖
    async lottery() {
      loading.showLoading()
      const res = await testDriveServices.giftLotteryByThirid({ appointId: this.appointId })
      loading.hideLoading()
      const { hasLottery= false } = res 
      if (!hasLottery) {
        this.giftInfo = res
        this.submitParams.productId = res.productId
        this.submitParams.productName = res.productName
        this.lotteryDialog = true
      } else {
        this.$toast(this.$t('该试驾单已抽取过试驾礼！'))
      }
    },
    onCityConfirm(data) {
      if (data && data.length > 0) {
        this.submitParams.province = data[0].name
        this.submitParams.city = data[1].name
        this.submitParams.region = data[2].name
      }
      this.showArea = false
    },
    // 礼品领取
    onSubmit() {
      const token = `Bearer ${this.accessToken}`
      this.$refs.form.validate().then(async() => {
        loading.showLoading()
        const params = {
          ...this.submitParams,
          appointId: this.appointId,
          // token: `Bearer ${this.$route.query.accessToken}`
          token
        }
        try {
          const res = await testDriveServices.giftReceiveByThirid(params, { verifyCode: this.submitParams.verifyCode })
          if (res.code === 0) {
            loading.hideLoading()
            this.$toast.success(this.$t('领取成功！'))
            this.submitParams = {
              consigneeName: '',
              consigneePhone: '',
              productId: '',
              productName: '',
              consigneeAddress: '',
              province: '',
              city: '',
              region: '',
            }
            setTimeout(() => {
              this.lotteryDialog = false
            }, 1500)
          } else if (res.code === 4000026) {
            // app重试获取token
            if (this.$route.query.from === 'app') {
              this.accessToken = this.updateToken()
              // this.$toast('刷新token-----,' + this.accessToken)  
              this.tokenCount++
              if (this.tokenCount <= 2) {
                this.onSubmit()
              } else {
                loading.hideLoading()
                this.$toast(res.message)  
                setTimeout(() => {
                  this.lotteryDialog = false
                }, 1500)
              }
            } else {
              loading.hideLoading()
              this.$dialog.confirm({
                title: '',
                message: res.message,
                confirmButtonText: '我知道了',
                showCancelButton: false
              }).then(() => {})
            }
          } else {
            loading.hideLoading()
            this.$toast.fail(res.message)
          }
        } catch (error) {
          this.$toast.fail(error.message)
          loading.hideLoading()
          setTimeout(() => {
            this.lotteryDialog = false
          }, 1500)
        } 
       
      })
    },
    // 获取token--refreshToken
    refreshToken () {
      const vm = this
      window.flutter_inappwebview.callHandler('refreshToken')
        .then(function (result) {
          vm.$toast('获取token-----,' + result)  
          vm.accessToken = result
        })
    },

    // 更新token--updateToken
    updateToken () {
      return new Promise((resolve, reject) => {
        window.flutter_inappwebview.callHandler('updateToken')
          .then(function (result) {
            return result
          })
      })
      // const vm = this
      // window.flutter_inappwebview.callHandler('updateToken').then(function (result) {
      //   vm.accessToken = result
      // })
    }
  }
}
</script>
<style lang="less" scoped>
  .receiveGift{
    background-image: url("./images/bg1.png");
    background-size: cover;
    max-width: 500px;
    height: 100vh;
    position: relative !important;
    padding-top: 150px;
    img{
      width: 100%;
    }
    .name{
      color: #EED484;
      font-size: 31px;
      min-width: 192px;
      height: 29px;
      line-height: 29px;
      padding-left: 19px;
    }
    .domain{
      width: 100%;
      display: flex;
      height: 25px;
      align-items: center;
      margin-top: 20px;
      font-size: 12px;
      padding-left: 19px;
      .domain-i{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #EED484;
        padding: 0 12px;
        border-radius: 30px;
        height: 25px;
        border: 1px solid #EED484;
        &:not(:last-of-type) {
          margin-right: 10px;
        }
        img{
          width: 12px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
    /deep/ .dialogContet{
      padding: 16px;
      font-size: 13px;
      color: rgba(13,23,26,0.65);
      p{
        height: 30px;
        line-height: 30px;
        &.title{
          font-size: 14px;
          font-weight: bold;
          color: @black;
        }
      }
    }
    .giftBox{
      width: calc(100% - 24px);
      height: 88px;
      display: flex;
      position: absolute !important;
      bottom: 120px;
      margin-left: 12px;
      overflow: hidden;
      .scrollBox{
        width: auto;
        display: flex;
        position: absolute;
        .gift-i{
          width: 88px;
          height: 88px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin-right: 4px;
          background: #fff;
          border: 2px solid @yellow;
          padding: 4px 0 4px 0;
          img{
            width: 60px;
            height: 60px;
            object-fit: scale-down;
          }
          .productName{
            width: 88px;
            padding: 0 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: center;
            color: @black;
            font-size: 10px;
            height: 12px;
          }
        }
      }
    }
    .footer{
      display: flex;
      position: absolute !important;
      bottom: 40px;
      width: 100%;
      justify-content: center;
      align-items: center;
      .outer{
        border: 1px solid @yellow;
        padding: 2px 3px;
        border-radius: 5px;
        .btn{
          width: fit-content;
          padding: 0 27px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 3px;
          color: @yellow;
          border: 1px solid @yellow;
        }
      }
    }
    .lotteryBox{
      display: flex;
      flex-direction: column;
      background: @black;
      padding: 24px 15px;
      color: @yellow;
      font-size: 13px;
      p{
        text-align: center;
      }
      .title{
        text-align: center;
        font-size: 16px;
        font-weight: bold;
      }
      .winIcon{
        width: 171px;
        margin: 0 auto;
        margin-bottom: 15px;
      }
      .giftPng{
        width: 100%;
        height: 154px;
        object-fit: scale-down;
        margin: 9px 0;
      }
      /deep/ .formBox{
        .van-cell{
          padding: 10px 0;
          background: @black;
          color: #fff;
          &::after{
            left: 0;
            right: 0;
            border-bottom: 1px solid @yellow;
          }
          &.van-field{
            display: flex;
            flex-direction: column;
            
            &:last-of-type::after{
              right: 0px;
              left: 0px;
              display: block;
            }
            .van-field__control{
              color: #fff;
            }
            .van-field__label{
              color: @yellow;
            }
          }
        }
        .submitBtn{
          border: 1px solid @black;
          border-radius: 100px;
          cursor: pointer;
          background: @yellow;
          width: 100%;
          margin-top: 29px;
          height: 32px
        }
      }
    }
  }
</style>